<template>
    <div>
        <sortData :columns="columns" :request="request" customKey="changeDate" @setSortBy="setSortBy" v-if="withSort"></sortData>
        <div class="r-table s-overflow-scroll">
            <table>

                <theadCustom :columns="columns" :request="request" @setSortBy="setSortBy"></theadCustom>

                <tr  :class="listData.length != 0 ? 'r-ht-0' : 'r-ht-230'" v-if="fetchData">
                    <td :colspan="columns.length">
                         <div class="r-isExpand r-fullCenter-flexColumn r-tabel-loading">
                            <img :src="require('@/assets/spinning-circles.svg')" alt="loading. . ">
                        </div>
                    </td>
                </tr>


                <tr  v-if="!fetchData && listData.length == 0">
                    <td :colspan="columns.length">
                        <div class="r-isExpand r-fullCenter-flexColumn r-ht-230">
                            <img :src="require('@/assets/nodata.svg')" alt="nodata" class="r-wd-50">
                            <p>Belum ada data</p>
                        </div>
                    </td>
                </tr>

                <tr v-for="(item, index) in listData" :key="`${item.id}`" @click="togglePopup(curNumber(index))">
                    <template v-for="(c,i) in columns">
                        <fieldCustom :label="c.label" :className="c.class" :key="`column-${i}`">
                            <slot :name="c.fieldName" :row="item" :index="index">
                                {{item[c.fieldName] || '-'}}
                            </slot>
                        </fieldCustom>
                    </template>
                </tr>
            </table>
            <pagination :request="request" :data="data" @setShow="setShow" @getData="getData" v-if="data != null && withPagination"></pagination>
        </div>
    </div>
</template>
<script>
import sortData from './SortData'
import theadCustom from './Thead'
import pagination from './Pagination'
import fieldCustom from './Field'

export default {
    emits: ['setShow','getData','setSortBy','togglePopup'],
    props: {
        columns: {
            require: true
        },
        data: {
            require: false,
            default: null
        },
        list: {
            default: null
        },
        request: {
            require: false
        },
        fetchData: {
            require: true
        },
        withAction: {
            default: true
        },
        withSort: {
            default: true
        },
        withPagination: {
            default: true
        },
    },
    computed: {
        listData(){
            return this.list || this.data.data || []
        }
    },
    mounted() {
    
    },
    components:{
        sortData,
        theadCustom,
        pagination,
        fieldCustom
    },
    methods: {
        setShow(value){
            this.$emit("setShow", value)
        },
        getData(value){
            this.$emit("getData", value)
        },
        setSortBy(value){
            this.$emit("setSortBy", value)
        },
        togglePopup(value){
            this.$emit("togglePopup", value)
        },
        curNumber(index){
            return index + (this.request.show * (this.request.page - 1)) + 1
        },
    }
}
</script>