import { mapActions } from 'vuex'

export default {
    components:{
        'loading' : () => import( /* webpackChunkName: "loading" */ `@/components/common/loading.vue`),
        'nodata' : () => import( /* webpackChunkName: "nodata" */ `@/components/common/nodata.vue`)
    },
    methods: {
        ...mapActions({
            showSnackbar: 'showSnackbar'
        }),
        getColumnData(value){
            return this.columns.find(x => x.fieldName == value)?.label || null
        },
        curNumber(index){
            return index + (this.request.show * (this.request.page - 1)) + 1
        },
        setTime() {
            this.fetchData = true
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.getData, 1000);
        },
        clearTime() {
            clearTimeout(this.typingTimer);
        },
        setSortBy(value){
            let request = {...this.request}
            if(value == request.sortBy) request.sorting = request.sorting == 'DESC' ? 'ASC' : 'DESC'
            request.sortBy = value
            this.setRequest(request)
            this.getData()
        },
        setShow(value){
            let request = {...this.request}
            request.show = value
            this.setRequest(request)
            this.getData()
        },
        getResponse(module){
            const status = this.$store.getters[`${module}/getStatus`]
            const msg = this.$store.getters[`${module}/getMsg`]
            return {status, msg}
        },
        setSearch(value) {
            let request = {...this.request}
            request.search = value       
            this.setRequest(request)
            this.getData()
        }
    }
}